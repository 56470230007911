.elements-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.elements-row {
    display: flex;
    justify-content: space-around;
}

.element {
    font-size: 16px;
    cursor: pointer;
    padding: 0 7px;
}

.element_active {
    background: lightgray;
    border-radius: 5px;
}

/*Text component*/
.text-component__button {
    margin: 10px 0 0 0;
}

/*Container component*/
.container-component__display_start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.container-component__display_end {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/*Video component*/

.video-component__player {
    background: lightgray;
    padding: 10px;
    border-radius: 5px;
}

.video-component__plug {
    display: flex;
    justify-content: center;
    align-items: center;
    background: lightgray;
}

.video-component__button {
    margin: 10px 0 0 0;
}

/*Image component*/

.image-component__plug {
    background: lightgray;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.image-component__dropzone {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #44B7C4;
    cursor: pointer;
    border-radius: 5px;
}

.image-component__dropzone-title {
    margin: 0;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
}

.image__name {
    font-weight: 600;
}

/*Table component*/
table {
    max-width: 520px;
    position: relative;
    border-collapse: collapse;
    overflow: auto;
}

table th {
    color: #6c6c6d;
    text-align: center;
    background: #f1f5f4;
    padding: 12px;
    border: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    cursor: pointer;
}


table td {
    min-width: 100px;
    height: 43px;
    text-align: center;
    background: #fff;
    border: 1px solid #dee2e6;
    padding: 12px;
    cursor: pointer;
}
.input {
    width: 75px;
    height: 20px;
    font-size: 15px;
    text-align: center;
    padding: 0;
    border: none;
    border-bottom: 1px solid #333;
    outline: none;
}

.input__th {
    color: #6c6c6d;
    background: #f1f5f4;
    font-weight: bold;
}

.table__button {
    border: 1px solid rgb(68, 183, 196);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    vertical-align: top;
    padding: 0 7px;
    background: rgb(68, 183, 196);
}

.table__button_col-plus {
    position: absolute;
    top: 0;
    right: -26px;
}

.table__button_col-min {
    position: absolute;
    top: 25px;
    right: -26px;
}

.table__button_row-plus {
    position: absolute;
    bottom: -25px;
}

.table__button_row-min {
    position: absolute;
    bottom: -25px;
    left: 28px;
}

.table-component__wrapper {
    padding: 0 0 30px 0;
    overflow: auto;
}

/*ToolBar component*/
.toolbar-component__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.toolbar-component__button {
    margin: 5px;
}

/*TopBar component*/
.topBar-component__button {
    margin: 0 0 0 10px;
}

.topBar-component__text-field {
    margin: 30px 0 0 0;
}

.topBar__buttons-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topBar__left-side {
    display: flex;
    align-items: center;
}

/*Settings panel component*/

.settings-panel-component__wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.settings-panel-component__button {
    margin: 5px;
}

/*Carousel*/
.carousel-wrapper {
    padding: 10px 0;
}
.carousel__settings-image-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}

.carousel__slide-image {
    width: 50%;
}

.carousel__block-wrapper {
    display: flex;
    justify-content: center;
}

.hid {
    overflow: hidden;
}